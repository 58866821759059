<template >
  <div class="table-container">
    <div class="table-toolbar">
      <div class="title">
        <div class="text">
          <span>先票后款</span>
          <!-- <span class="desc">(一级)</span> -->
        </div>
      </div>
      <div class="filters">
        <a-input class="filter" placeholder="公司名称" v-model:value="filter.company"></a-input>
        <a-button class="filter-btn" type="primary" @click="handleSearch">搜索</a-button>
      </div>
    </div>
    <div class="table">
      <a-table size="middle" class="tableRef" :columns="columns" :row-key="record => record.id" :data-source="dataSource"
        :pagination="pagination" :loading="loading" @change="handleTableChange">
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex == 'id'">
            <div class="drapHandle" style="cursor: pointer;">
              <holder-outlined />
              <span style="margin-left:6px;">{{ record.id }}</span>
            </div>
          </template>

          <template v-if="column.dataIndex == 'icon'">
            <div>
              <FjList :fjlist="record.icon" />
            </div>
          </template>

          <template v-if="column.dataIndex == 'file_list'">
            <span style="margin-right:2px;" v-for="images in record.file_list" :key="images">
              <a-image :width="20" :src="images"/>
            </span>
          </template>

          <template v-if="column.dataIndex === 'operation'">
            <div class="operations">
              <a-button v-if="!record.invoice" type="primary" size="small" style="margin-right:5px" @click="edit(record)">上传发票</a-button>
              <a-button v-else type="primary" size="small" style="margin-right:5px" @click="download(record)">下载发票</a-button>
              
              <a-button v-if="!record.pay_status"  type="danger" size="small" @click="getmoneyConfirm(record)">确认收款</a-button>
              <a-button v-else type="success" disabled size="small">已经收款</a-button>
            </div>
          </template>
        </template>
      </a-table>
    </div>
    <a-modal v-model:visible="visible" title="确认收款？" @ok="getmoney()">
      <div style="font-size:24px; margin-bottom:15px;">{{companyname}}</div>
      <div style="font-size:18px; margin-bottom:15px;">请选择收款日期</div>
      <a-date-picker v-model:value="getmoneyDate" />
    </a-modal>
    <RoleInMenu ref="roleInMenuRef" @refreshList="handleSearch" />
    <Edit ref="editRef" @close="current = null;" :current="current" @refreshList="handleSearch" />
  </div>
</template>
<script>
import { usePagination } from 'vue-request';
import { computed, defineComponent } from 'vue';
import service from '@/service/service';
import { message} from 'ant-design-vue';
import { HolderOutlined } from '@ant-design/icons-vue';
import Sortable from 'sortablejs';
import FjList from '@/components/common/fjList.vue';
import Edit from './Edit.vue';

export default defineComponent({
  components: {
    Edit,
    HolderOutlined,
    FjList
  },

  data() {
    return {
      currentTab: '',
      filter: {},
      current: null,
      visible:0,
      companyname:"",
      getmoneyDate:"",
      id:0,
    }
  },
  watch: {
    dataSource(newVal) {
      if(newVal) {
        console.log(newVal);
      this.initDrap();
      }
    }
  },
  methods: {
    initDrap() {
      // console.log('hello');
      this.$nextTick(() => {
        let el = document.querySelector('.tableRef .ant-table-tbody');

        new Sortable(el, {
          handle: '.drapHandle',
          animation: 250,
          onEnd: (evt) => {
            let tempList = [...this.dataSource];
            let temp = tempList.splice(evt.oldIndex, 1)[0];
            tempList.splice(evt.newIndex, 0, temp);
            this.submitSort(tempList);
          }
        })
      })
    },
    async submitSort(list) {
      this.listLoading = true;
      let { msg, code } = await service.set_category_sort_num({
        id: list.map(m => m.id).join(',')
      });
      this.listLoading = false;
      if (code && code == '1') {
        message.success(msg || '成功');
        // this.getSubList();
      }
    },
    // 切换页码调用获取
    handleTableChange(pag, filter, sorter) {
      this.run({
        limit: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order ? (sorter.order.includes('asc') ? 'asc' : 'desc') : '',
        ...this.filter,
      });
    },
    download(item) {        
      var url = item.invoice // 下载文件的url        
      const link = document.createElement('a')        
      link.href = url
      link.download = "959595" // 下载文件的名称
      link.click()      
    },
    // 筛选
    handleSearch() {
      this.run({
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        ...this.filter
      })
    },
    edit(item) {
      this.current = item;
      this.$refs['editRef'].onShow();
    },
    getmoneyConfirm(item){
      this.visible = 1
      this.companyname = item.companyname
      this.id = item.id
    },
    async getmoney() {
      const { code, msg } = await service.get_money({ id: this.id,getmoneyDate:this.getmoneyDate });
          if (code && code == '1') {
            message.success(msg || '成功');
            this.visible = 0;
            this.handleSearch();
          }
          else {
            message.error(msg || '失败');
          }
    },
    menuEdit(record) {
      this.$refs['roleInMenuRef'].init(record.id);
    },
    
  },
  setup() {
    // 声明并调用一次数据拉取
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = usePagination(service.get_order_invoice, {
      formatResult: res => {
        res.data.total_num = res.total_num;
        return res.data;
      },
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'limit',
        totalKey: 'total_num',
      },
    });
    // 分页所需的属性
    const pagination = computed(() => ({
      total: total.value,
      current: current.value,
      pageSize: pageSize.value,
    }));

    // data用
    return {
      dataSource,
      pagination,
      loading,
      columns: [
        {
          title: '订单日期',
          dataIndex: 'create_time',
        },
        {
          title: '主账号',
          dataIndex: 'account',
        },
        {
          title: '企业名称',
          dataIndex: 'companyname',
        },
        
        {
          title: '套餐名称',
          dataIndex: 'vipname',
        },
        {
          title: '金额',
          dataIndex: 'total_amount',
        },
        {
          title: '合同照片',
          dataIndex: 'file_list',
        },
        {
          title: '开票信息',
          dataIndex: 'status_text',
        },
        {
          title: '业务员',
          dataIndex: 'salesman',
        },
        
        
        // {
        //   title: '分类说明',
        //   dataIndex: 'description',
        // },
        {
          title: '操作',
          dataIndex: 'operation'
        }
      ],
      run,
    };
  },
})
</script>
<style lang="less" scoped></style>